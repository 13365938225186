@font-face {
  font-family: "Nesans Semi Bold";
  src: url("../../../public/fonts/Nesans-Semi-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  font-family: "Nesans Semi Bold", sans-serif;
  background-color: rgb(0, 0, 0);
}

*,
::after,
::before {
  padding-left: 0px;
   /* background-attachment: fixed;  */
  /* -webkit-background-attachment: scroll; */
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: auto 1;
}
@supports ( -webkit-touch-callout : none) {
  *,
::after,
::before {
  background-attachment:scroll
  }
  }
  
  @supports not ( -webkit-touch-callout : none) {
    *,
    ::after,
    ::before {
  background-attachment: fixed;
  }
  }
a {
  text-decoration: none;
  color: white;
}

footer {
  text-align: center;
  color: white;
  opacity: 0.9;
}

.modal{
  left:35%; 
  margin-right:auto;
  margin-left:auto;
  margin-top:auto;
  margin-bottom:auto;
  width:25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  background-color:transparent;
  color:white;
  /* border: 1px solid white; */
  z-index: 99999;
  font-size: 130%;
}

.close{
  cursor:pointer;
  float:right;
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

.modal-text{
  text-align: center;
}

.popup{
  width: 100%;
  max-height:80vh;
}

.titles {
  color: white;
  text-align: center;
  margin-top: 0%;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 500%;
}

.presentation {
  position: absolute;
  background-image: url("/public/img/imagen_fondo5.jpg");
  background-position: center center;
  margin-top: -9.4%;
  margin-left: -0.5%;
  margin-right: -0.5%;
  z-index: -1;
  padding-top: 70%;
  width: 99.99%;
  opacity: 0.3;
}

.logo2{
 width: 35%;
 position: relative;
 justify-content: center;
 margin-top: 2%;
 margin-bottom: 2%;
 margin-inline: auto;
 display: flex; 
}

.btn-delay{
  margin-bottom: 36.5%;
}

.btn-flex-reservas{
margin-top: 0%;  
display: flex;
justify-content: center;
align-content: space-between;
}

.btn-flex {
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-content: space-between;
}

.animated1 {
  animation: scale 1.5s ease-in;
  background-color: rgb(255, 255, 255, 0.55);
  color: rgba(0, 0, 0, 1);
  width: 18vw;
  height: 6vh;
  text-align: center;
  text-decoration: none;
  font-family: "Nesans Semi Bold", sans-serif;
  margin: 4px 15px;
  cursor: pointer;
  border-radius: 15px;
}
.animated1:hover{
  background-color: rgb(255, 255, 255, 1);
}

.animated2 {
  animation: scale 2s ease-in;
  background-color: rgb(255, 255, 255, 0.55);
  color: rgba(0, 0, 0, 1);
  width: 18vw;
  height: 6vh;
  padding-top: 2.5vh;
  text-align: center;
  text-decoration: none;
  font-family: "Nesans Semi Bold", sans-serif;
  margin: 4px 15px;
  cursor: pointer;
  border-radius: 15px;
}

.animated2:hover{
  background-color: rgb(255, 255, 255, 1);
}

.animated3 {
  animation: scale 1s ease-in;
  background-color: rgba(170, 46, 52, 0.55);
  color: rgb(255, 255, 255, 1);
  width: 18vw;
  height: 6vh;
  padding:2.5vh, 1vw, 4.5vh, 1vw;
  text-align: center;
  text-decoration: none;
  font-family: "Nesans Semi Bold", sans-serif;
  margin: 4px 15px;
  cursor: pointer;
  border-radius: 15px;
}

.animated3:hover{
  background-color: rgba(170, 46, 52, 1);
}

.animated4 {
  animation: scale 2.5s ease-in;
  background-color: rgb(255, 255, 255, 0.55);
  color: rgb(0, 0, 0, 1);
  width: 18vw;
  height: 6vh;
  padding-top: 2.5vh;
  text-align: center;
  text-decoration: none;
  font-family: "Nesans Semi Bold", sans-serif;
  margin: 4px 15px;
  cursor: pointer;
  border-radius: 15px;
}
.animated4:hover{
  background-color: rgb(255, 255, 255, 1);
}



@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.container-father{
  text-align:center;
}
.container-btn {
  display: flex;
  padding-bottom: 15%;
  height: 150px;
  width: 100%;
  justify-content: space-around;
  display: inline-flex;
  width:70%;
}

.btn {
  background-color: #000000;
  color: white;
  padding: 20px 10px 0px 10px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 24px;
  cursor: pointer;
  border: 1px solid white;
  text-shadow: 3px 2px 2px black;

}


.btn3 {
  background-color: #000000;
  color: white;
  padding: 0px 10px 220px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid white;
  text-shadow: 3px 2px 2px black;
  border-radius: 15px;
  width: 12vw;
  height: 10vh;
  align-items: center;


}

.btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  border-bottom: 1px solid white;
}

.btn-edit{
width:50%;
}

.carta-img{
margin-top:-5%;
}

.admin {
  text-align: center;
  margin-bottom: 4%;
}

.titleadmin {
  color: white;
  margin-top: 7%;
}

.labeladmin {
  color: white;
  text-align: center;
  margin-top: 0%;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 100%;
  margin-bottom: -0.3%;
}

.labeladmin1 {
  color: white;
  text-align: center;
  margin-top: 0%;
  font-family: "Nesans Semi Bold", sans-serif;
}

.labeladmin2 {
  color: white;
  text-align: center;
  margin-top: 0%;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 100%;
}

.about-us{
  margin-top: 25%;
}

.about-us2{
  margin-top: 30%;
}
.suntaka{
padding-top: 2%;
}
.suntaka2{
  padding-top: 2%;
  }

.container-aboutus1 {
  display: flex;
  margin-top: 0%;
}

.container-aboutus2 {
  display: flex;
  margin-top: 0%;
  
}

.about-us-left {

  flex: 1;
  padding-bottom: 1%;
  line-height: 2;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
}

.image-suntaka-1{
  flex:1;
  width:110%;

}

.image-suntaka-2{
  flex:1;
  display:none;
  width:110%;
}

.about-us-right {
  flex: 1;
  padding-bottom: 1%;
  line-height: 1.5;
  padding-right:9%;
}
.about-us-left2 {
  flex: 1;
  padding-bottom: 1%;
  line-height: 1.5;
  padding-left:7%;
}

.about-us-right2 {
  padding-top: 1%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 1%;
  padding-left:5%;
}

.image-chef-1{
  flex:1;
  width:100%;
/* padding-left:5%; */
}

.image-chef-2{
  flex:1;
  width:100%;
  /* padding-left:5%; */
}
.image-chef-3{
  flex:1;
  width:100%;
  display:none;
  /* padding-left:5%; */
}

.aboutus-text {
  color: white;
  text-align: justify;
  margin-top: 0%;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 140%;
}

.aboutus-text2 {
  color: white;
  margin-top: 0%;
  text-align: center;
  align-items: center;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 140%;
}

.embajadores-btn{
  padding: 20px 40px 20px 40px;
  border: 2px solid rgb(252, 63, 63);
  border-radius: 15px;
}

.suntaka {
  margin-left: 10%;
  margin-top: 0%;
}

.barrido {
  position: absolute;
  background-image: url("/public/img/barrido.jpg");
  margin-top: 5%;
  margin-left: -0.5%;
  margin-right: -0.5%;
  z-index: -1;
  padding-top: 20%;
  width: 99.99%;
  opacity: 0.9;
  filter: brightness(1.4);
  border-bottom:none;
}



.pdfReader {
  height: 48vh;
}

.image {
  max-height: 40vh;
  margin-left: 0.5%;
  margin-right: 0.5%;
  /* width: 90%; */
  width: 30%;
  animation: hover 2s ease-in-out;
  animation-delay: 0.5s;
  cursor: pointer;
}

.image:active {
  max-height: 90vh;
  max-width: 60vh;
  transform: scale(2);
  transform-origin: left top;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.barrido1 {
  position: absolute;
  background-image: url("/public/img/barrido1.jpg");
  margin-top: 12%;
  margin-left: -0.5%;
  margin-right: -0.5%;
  z-index: -1;
  padding-top: 20%;
  width: 99.99%;
  opacity: 0.9;
  filter: brightness(1.4);
  border-bottom:none;
}

.container-gallery {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content:center;
  margin-top:46%;
  padding-left:2%;
  padding-right:2%;
  box-sizing: border-box;
  width:99.99%;
  max-width:99.99%;
}

.container-gallery2 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-left:2%;
  padding-right:2%;
  box-sizing: border-box;
  width:99.99%;
  max-width:99.99%;
  margin-top: 1%;
}

.card {
  margin-top: 5%;
}

.frase {
  text-align: center;
  color: white;
  margin-top: 1%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
}

.cita, .citador {
  font-size: 150%;
}

.barrido2 {
  position: absolute;
  background-image: url("/public/img/carrusel9.jpg");
  margin-top: 2%;
  margin-left: -0.5%;
  margin-right: -0.5%;
  z-index: -1;
  padding-top: 20%;
  width: 99.99%;
  opacity: 0.9;
  filter: brightness(1.4);
}


.reservation-container {
  margin-top: 25%;
  text-align: center;
}

.reservation-text {
  margin-top:-3%;
  color: white;
  text-align: center;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 140%;
}

.reservation-text2 {
  margin-top:0%;
  text-align: center;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 240%;
  margin-bottom: 0%;
}

.reservation-text3 {
  margin-top: 0%;
  color: white;
  text-align: center;
  font-family: "Nesans Semi Bold", sans-serif;
  font-size: 140%;
}

.reservation-text-color {
  color: rgb(252, 63, 63);
}

.reservas-container {
  margin-top: 4%;
  text-align: center;
  align-items: center;
  margin-bottom: 32%;
}

.reservas {
  overflow: scroll;
  width: 50%;
  min-height: 800px;
  border: none;
}

.reservaonline{
padding: 20px 40px 20px 40px;
border:2px solid rgb(252, 63, 63);
border-radius: 15px;
font-size: 140%;
}

.barrido3 {
  position: absolute;
  background-image: url("/public/img/barrido3-1.jpg");
  margin-top: -24%;
  margin-left: -0.5%;
  margin-right: -0.5%;
  z-index: -1;
  padding-top: 20%;
  width: 99.99%;
  opacity: 0.9;
  filter: brightness(1.4);
}

.carousel {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom:30%;
  border-bottom: 1px solid white;
}

.carousel img {
  width: 80%;
}

.next {
  position: absolute;
  flex:1;
  top: 45%; 
  background-color: #000000;
  color: white;
  padding: 20px 40px 20px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  border: 2px solid rgb(252, 63, 63);
  text-shadow: 3px 2px 2px black;
  border-radius: 15px;

}

.next2 {
  position: absolute;
  flex:1;
  top: 70%; 
  background-color: #000000;
  color: white;
  padding: 20px 40px 20px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 47px;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  text-shadow: 3px 2px 2px black;
  border-radius: 15px;

}

.abanico {
  position: relative;
  justify-content: center;
  margin-top: -2%;
  width: 12%;
  margin-inline: auto;
  display: flex;
}

.footer-container {
  align-items: center;
  text-align: center;
  display: flex;
  margin-bottom: 5vh;
}

.footer-section2 h3{
  padding-top: 2.5%;
}
 .footer-section2 p{
  line-height: 2.15vh;
} 

.footer-section1 {
  flex: 1;
}
.footer-section2 {
  flex: 1;
}
.google {
  margin-right: 3vw;
}

.instagram {
  margin-top: 1vh;
  margin-left: 3vw;
}

.tripadvisor {
  margin-left: 3vw;
}

.copyright {
  margin-top: 4%;
}

/* .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 24px;
  font-weight: bold;
  color:white;
} */

.card-background{
  position: absolute;
   background-image: url("/public/img/backcard.jpg"); 
 /* margin-left: -0.5%;
  margin-right: -0.5%; */
  z-index: -1;
  padding-top: 215%;
  width: 99.99%;
  opacity: 0.9;
}

.sugested-background{
  position: absolute;
   background-image: url("/public/img/backsugested.jpg"); 
 /* margin-left: -0.5%;
  margin-right: -0.5%; */
  z-index: -1;
  padding-top: 185%;
  width: 99.99%;
  opacity: 0.9;
}
.sake-background{
  position: absolute;
   background-image: url("/public/img/backsake.jpg"); 
 /* margin-left: -0.5%;
  margin-right: -0.5%; */
  z-index: -1;
  padding-top: 215%;
  width: 99.99%;
  opacity: 0.9;
}
.desserts-background{
  position: absolute;
   background-image: url("/public/img/backdesserts.jpg"); 
 /* margin-left: -0.5%;
  margin-right: -0.5%; */
  z-index: -1;
  padding-top: 185%;
  width: 99.99%;
  opacity: 0.9;
}
.card-container{
  align-items: center;
  display: flex;
  flex-direction: column;
}
.pdf-sugested{
  margin-top: 5%;
width:80%;
}
.pdf-card{
  margin-top: 5%;
width:70%;
}
.pdf-sake{
  margin-top: 5%;
width:70%;
}
.pdf-desserts{
  margin-top: 5%;
width:80%;
}

_::-webkit-full-page-media, _:future, :root .barrido1 {
  padding-top:0%;
  background-image: url("/public/img/barridosafari.png");

}
_::-webkit-full-page-media, _:future, :root .barrido {
  padding-top:0%;
  background-image: url("/public/img/barridosafari.png");

}
_::-webkit-full-page-media, _:future, :root .barrido2 {
  padding-top:0%;
  background-image: url("/public/img/barridosafari.png");

}
_::-webkit-full-page-media, _:future, :root .barrido3 {
  padding-top:0%;
  background-image: url("/public/img/barridosafari.png");

}
_::-webkit-full-page-media, _:future, :root .about-us2{
  margin-top: 7%;
}
_::-webkit-full-page-media, _:future, :root .container-gallery{
  margin-top: 7%;
}
_::-webkit-full-page-media, _:future, :root .reservation-container {
  margin-top: 7%;
}
_::-webkit-full-page-media, _:future, :root .reservas-container {
  margin-bottom: 28%;
}
_::-webkit-full-page-media, _:future, :root #review{
  margin-top:-17%;
}

@media not all and (min-resolution:.001dpcm) {
  .barrido, .barrido2, .barrido3 {
    padding-top:0%;
    background-image: url("/public/img/barridosafari.png");
  }
  .about-us2{
    margin-top: 7%;
  }
  .reservation-container {
    margin-top: 7%;
  }
  .reservas-container {
    margin-bottom: 32%;
  }
  #review{
    margin-top:-21%;
  }
}

/* @media screen and (-webkit-min-device-pixel-ratio:0) {

  .barrido, .barrido2, .barrido3 {
    background-image: transparent;
    border-bottom: 1px solid white;
  }
} */


@media screen and (min-width: 2000px) {
  .btn3 {
    padding: 0px 10px 290px 10px;
  }
  .animated1, .animated2, .animated3, .animated4{
    font-size: 40px;
    height: 60px;
    padding-top: 14px;
  }
  .next, .next2, .reservaonline, .embajadores-btn{
   padding: 20px 40px 20px 40px;
  }
  .aboutus-text,
  .reservation-text,
  .reservation-text3,
  .aboutus-text2,
  .reservaonline,
  .next {
    font-size: 240%;
  }
  .next2 {
    top:60%;
    font-size: 300%;
  }
  .presentation{
    padding-top:60%;
  }
  .image {
    max-height: 35vh;
  }
  .about-us{
    margin-top: 15%;
  }
  .btn-delay{
    margin-bottom: 25%;
  }
  .carousel-item {
    width: 30%;
  } 

  .image-chef-2{
    display:none;
  } 
  .next{
    top:34%;
  }
  .carousel{
    padding-bottom: 15%;
  }
  .footer-section2 h3{
    padding-top: 1%;
  }
   .footer-section2 p{
    line-height: 1.5vh;
  } 
}

@media screen and (max-width: 1999px) {
  .animated1, .animated2, .animated3, .animated4{
    width: 185px;
    font-size: 20px;
    height: 38px;
    padding-top: 14px;
  }
  .carousel-item {
    width: 50%;
  }
  .image-chef-2{
    display:none;
  }
  .image {
    max-height: 36vh;
  }
}

@media screen and (max-width: 1499px) {
  .carousel-item {
    width: 70%;
  }
  .btn3 {
    padding: 0px 10px 180px 10px;
  }
  .image {
    max-height: 32vh;
  }
}

@media screen and (max-width: 1111px) {
  .modal{
    font-size: 120%;
  }
  .barrido2 {
    background-image: url("../../../public/img/carrusel10.jpg");
  }
 
  .animated1, .animated2, .animated3, .animated4 {
    width: 185px;
    height: 38px;
    padding-top: 14px;
    font-size: 120%;
  }
  .image {
    max-height: 29vh;
  }
  .next, .next2, .reservaonline, .embajadores-btn{
    padding: 16px 30px 16px 30px;
  }

  .presentation {
    padding-top: 75%;
  } 

  .about-us{
    margin-top: 22%;
  }
  .btn-delay{
    padding-bottom:15%;
  }
  .container-aboutus1 {
    margin-top: 0%;
  }
  .image-chef-2{
    display:block;
  }
  .container-btn {
    width:80%;
    }
  .btn-text {
    font-size: 80%;
  }
  
  .btn3 {
    padding: 0px 10px 140px 10px;
  }
  .reservas {
    width: 70%;
  }
  .carousel-item {
    width: 80%;
  }
  .footer-section2 h3{
    padding-top: 4%;
  }
   .footer-section2 p{
    line-height: 3.7vh;
  } 
}

@media screen and (max-width: 1024px) {
  .modal{
    width:40vw;
    font-size:110%;
    left:30%; 
  }
  
  .logo2 {
    width: 50%;
  }
  .animated1, .animated2, .animated3, .animated4 {
    width: 170px;
    height: 34px;
    padding-top: 12px;
    font-size: 100%;
    margin: 4px 13px;
  }
  .animated1,  .animated2,   .animated4 {
    background-color: rgb(255, 255, 255, 1);
  }

  .animated3 {
    background-color: rgba(170, 46, 52, 1);
  }
  
  .btn-delay{
    padding-bottom: 5%;
  }
  .image {
    max-height: 25vh;
  }
  .next, .reservaonline, .embajadores-btn{
    padding: 14px 30px 14px 30px;
    border-radius: 12px;
    border: 1px solid rgb(252, 63, 63);
  }


  .btn-text {
    font-size: 70%;
  }

  .titles {
    font-size: 400%;
  }

  .presentation {
    padding-top: 90%;
  } 

  .about-us{
    margin-top: 27%;
  }

  .container-aboutus1 {
    margin-top: 0%;
  }

  .reservation-text2{
    font-size:170%;
  }

  .aboutus-text,
  .reservation-text,
  .reservation-text3,
  .aboutus-text2,
  .reservaonline,
  .next{
    font-size: 120%;
  }
   
  .next2 {
    font-size:180%
  }

  .footer-container,
  .copyright,
  .frase {
    font-size: 80%;
  }

  .btn3 {
    width: 100px;
    height: 70px;
    padding: 0px 10px 120px 10px;
  }
  .footer-section2 h3{
    padding-top: 2.5%;
  }
   .footer-section2 p{
    line-height: 2.4vh;
  } 
}

@media screen and (max-width: 850px) {
  .modal{
    font-size: 100%;
  }

  .barrido2 {
    background-image: url("../../../public/img/carrusel11.jpg");
  }

  .animated1, .animated2, .animated3, .animated4  {
    width: 130px;
    height: 26px;
    padding-top: 12px;
    font-size: 80%;
    border-radius: 10px;
  }
  .btn-delay{
    margin-bottom: 38%;
  }

  .about-us{
    margin-top: 30%;
  }
  .about-us2{
    margin-top:33%;
      }
      .card{
        padding-top:3%;
      }
      .reservation-container{
        margin-top:31%;
      }
      .reservas-container{
        margin-bottom:37%;
      }
      #review{
    padding-top:4%;
      }
  .image {
    max-height: 21vh;
  }
  .next, .next2, .reservaonline, .embajadores-btn{
    padding: 12px 25px 12px 25px;
    border-radius: 10px;
   }

  .btn-text {
    font-size: 65%;
    padding-bottom:15px;
  }
  .btn3 {
    width: 100px;
    height: 70px;
    padding: 0px 10px 120px 10px;
  }
  .barrido3 {
    background-image: url("../../../public/img/barrido3.jpg");
  }
}

@media screen and (max-width: 768px) {
  .modal{
    font-size: 200%;
    width:70vw;
    left:15%; 
  }  
  .btn-delay{
    margin-bottom: 40%;
  }
  .image {
    max-height: 17vh;
  }
  .next, .next2, .reservaonline, .embajadores-btn{
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
   }
  .container-btn2 {
    font-size: 17px;
    height: 130px;
    width: 45%;
  }

  .btn-text {
    font-size: 55%;
  }
  .card{
    padding-top:3%;
  }
  .reservation-container{
    margin-top:31%;
  }
  .reservas-container{
    margin-bottom:37%;
  }
  #review{
padding-top:4%;
  }
  .btn3 {
    width: 80px;
    height: 40px;
    padding: 0px 10px 120px 10px;
  }
  .titles {
    font-size: 300%;
  }
  .about-us2{
    margin-top:34%;
      }
  .about-us-left{
    flex-basis:40%;
  }
  .about-us-right{
    flex-basis: 60%;
  }
  .about-us-left2{
    flex-basis:60%;
  }
  .about-us-right2{
    flex-basis: 40%;
  }
  .reservation-text2{
    font-size:150%;
  }

  .aboutus-text,
  .reservation-text,
  .reservation-text3,
  .aboutus-text2,
  .reservaonline,
  .next {
    font-size: 80%;
  }
  .next2 {
    font-size:120%
  }
  .cita,
  .citador {
font-size: 240%;
  }
  .footer-container,
  .copyright,
  .frase {
    font-size: 50%;
  }
  
  .reservas {
    width: 90%;
  }
  .carousel {
    padding-bottom:35%;
    }
    
    .carousel img {
      width: 100%;
    }
    .footer-section2 h3{
      padding-top: 3.5%;
    }
     .footer-section2 p{
      line-height: 1.8vh;
    } 
}

@media screen and (max-width: 600px) {
  .modal{
    font-size: 200%;
  }
 
  .image {
    max-height: 14vh;
  }
  .barrido2 {
    background-image: url("../../../public/img/carrusel12.jpg");
  }
  
  .about-us{
    margin-top: 25%;
  }
  .about-us2{
    margin-top:33%;
      }
      .card{
        padding-top:5%;
      }
      .reservation-container{
        margin-top:31%;
      }
      .reservas-container{
        margin-bottom:37%;
      }
      #review{
    padding-top:4%;
      }
  .btn-delay{
    margin-bottom: 40%;
  }
  .next, .next2, .reservaonline, .embajadores-btn{
    padding: 8px 15px 8px 15px;
    border-radius: 8px;
   }
  .reservas {
    width: 100%;
  }
  .carousel {
    padding-bottom:35%;
    }
    
    .carousel img {
      width: 100%;
    }
}

@media screen and (max-width: 540px) {
  .modal{
    font-size: 200%;
  }
  .btn-flex{
    flex-direction:column;
    justify-content: center;
    align-items:center;
  }
    .animated3{
      margin-top:10%;
      margin-bottom: 5%;
      
    }
    .animated1, .animated2{
      margin-bottom: 9%;
    }
    .animated1, .animated2, .animated3, .animated4{
      width: 38vw;
      font-size: 85%;
      height: 3.5vh;
padding-top:1vh;
    }
  .btn-delay{
    margin-bottom: 85%;
  }
  .presentation {
    padding-top: 180%;
  }

  .logo2{
    width: 70%;
  }
  .image {
    max-height: 12vh;
  }
  .container-btn {
    padding-bottom: 0%;
  }
  .btn-flex-reservas{
    margin-top: 5%;
    }

  .btn-flex{
    margin-bottom: 3%;
  }

  .about-us-right {
    padding-right:0%;
    }
    .card{
      padding-top:5%;
    }
    .reservation-container{
      margin-top:31%;
    }
    .reservas-container{
      margin-bottom:37%;
    }
    #review{
  padding-top:4%;
    }
  .titles {
    font-size: 200%;
  }

  .about-us{
padding-top: 35%;
  }

  .image-suntaka-2{
    display:block;
  }
  .image-chef-3{
    display:block;
  }
  .btn-text {
    font-size: 45%;
    padding-bottom:10px;
  }
  .btn3 {
    width: 60px;
    height: 17px;
    padding: 0px 10px 100px 10px;
  }
  .carousel {
    padding-bottom:40%;
    }
    
    .carousel img {
      width: 100%;
    }
    .footer-container {
      margin-bottom: 1vh;
    }
    .footer-container, .copyright {
      font-size: 85%;
    }
    .google {
      width:5%;
    }
    
    .facebook {
      width:5%;
    }

    .instagram {
      width:5%;
    }
    
    .tripadvisor {
      width:5%;
    }
    .pdf-sugested{
      margin-top: 20%;
    }
    .pdf-desserts{
      margin-top: 20%;
    }
    .footer-section2 h3{
      padding-top: 5%;
    }
     .footer-section2 p{
      line-height: 2.5vh;
    } 
}

@media screen and (max-width: 480px) {
  .modal{
    font-size: 250%;
  }
  /* .animated1, .animated2, .animated3, .animated4 {
    width: 110px;
    height: 20px;
    padding-top: 8px;
    font-size: 70%;
  } */
  
  .btn-delay{
    margin-bottom: 89%;
  }
  .presentation {
    padding-top: 190%;
  }
  .about-us{
    padding-top: 42%;
      }
      .about-us2{
        margin-top:35%;
          }
.image {
  max-height: 10vh;
}
  .container-btn2 {
    font-size: 16px;
    height: 70px;
    width: 70%;
  }
  .about-us-right {
    padding-right:-7%;
    margin-left:-3%;
    }
    .image-chef-1, .image-chef-2, .image-chef-3{
      width:105%;
    }
  .about-us-left2{
    padding-left:2%;
  }
  .btn-text {
    font-size: 40%;
  }
  .btn3 {
    width: 50px;
    height: 0px;
    padding: 0px 10px 100px 10px;
  }
  .card{
    padding-top:5%;
  }
  .reservation-container{
    margin-top:31%;
  }
  .reservas-container{
    margin-bottom:37%;
  }
  #review{
padding-top:5%;
  }
  .titles {
    font-size: 200%;
  }
  
  .reservation-text2{
    font-size:130%;
  }
  .next{
    top:35%;
  }
  .aboutus-text,
  .reservation-text,
  .reservation-text3,
  .aboutus-text2,
  .reservaonline,
  .next  {
    padding-left: 6%;
    padding-right: 6%;
    font-size: 70%;
  }
  .next2 {
    font-size:100%
  }
  .cita,
  .citador {
font-size: 240%;
  }
  .footer-container,
  .copyright,
  .frase {
    font-size: 30%;
  }
  .carousel {
    padding-bottom:40%;
    }
    
    .carousel img {
      width: 100%;
    }
    .footer-container, .copyright {
      font-size: 80%;
    }
}

@media screen and (max-width: 400px) {
  .modal{
    font-size: 250%;
  }

  .animated1, .animated2, .animated3, .animated4{
    width: 42vw;
    font-size: 80%;
    height: 3vh;
    padding-top:1vh;
    border-radius: 6px;
  }
  .barrido2 {
    background-image: url("../../../public/img/carrusel13.jpg");

  }
  
  .btn-delay{
    margin-bottom: 104%;
  }
 .about-us{
  padding-top: 24%;
} 

  .btn-flex{
    margin-bottom: 40%;
  }
  .image {
    max-height: 8vh;
  }
  .card{
    padding-top:6%;
  }
  .reservation-container{
    margin-top:33%;
  }
  .reservas-container{
    margin-bottom:37%;
  }
  #review{
padding-top:5%;
  }
  .container-btn {
    width:90%;
    }
  .btn-text {
    font-size: 35%;
    padding-top:5px;
  }
  .btn3 {
    width: 40px;
    height: -17px;
    padding: 0px 10px 100px 10px;
  }
  .carousel {
    padding-bottom:45%;
    }
    
    .carousel img {
      width: 100%;
    }
    .cita,
  .citador {
font-size: 240%;
  }
}

@media screen and (max-width: 320px) {
  
  .animated1, .animated2, .animated3, .animated4{
    width: 42vw;
    font-size: 70%;
    height: 3vh;
    padding-top:1vh;
    border-radius: 6px;
  }

  .container-btn2 {
    font-size: 16px;
    height: 60px;
    width: 70%;
  }
 
  .btn-text {
    font-size: 27%;
  }
  .btn3 {
    width: 30px;
    height: -27px;
    padding: 0px 8px 80px 8px;
    border-radius: 10px;
  }
  .titles {
    font-size: 100%;
  }
   .presentation {
    padding-top: 190%;
  } 
  .container-btn {
    width:100%;
    }
    .btn-delay{
      margin-bottom: 113%;
    }
   .about-us{
    padding-top: 17%;
  } 
  .about-us2{
margin-top:33%;
  }
.image {
  max-height: 7vh;
}
.card{
  padding-top:3%;
}
.reservation-container{
  margin-top:19%;
}
.reservas-container{
  margin-bottom:37%;
}
#review{
  padding-top:5%;
    }
  .barrido2{
    margin-top:-10%;
  }
  .reservation-text2{
    font-size:100%;
  }
.next{
  top:30%;
}
  .aboutus-text,
  .reservation-text,
  .reservation-text3,
  .aboutus-text2,
  .reservaonline,
  .next{
    padding-left: 4%;
    padding-right: 4%;
    font-size: 65%;
  }
  .next2 {
    font-size:90%
  }
  .cita,
  .citador {
font-size: 290%;
  }

  .carousel {
    padding-bottom:50%;
    }
    
    .carousel img {
      width: 100%;
    }
    .footer-container, .copyright {
      font-size: 75%;
    }
    
}

